@mixin xs() {
    html body {
        min-width: 320px;
        overflow-x: auto;
    }
}

// welcome section
@mixin welcome--xs() {
    .welcome-img{
        display: none;
    }
}

@mixin welcome--sm() {
    .welcome-img{
        margin-top: 20px;
    }
}

@mixin welcome--lg() {
    section.welcome {
        h1 {
           font-size:  3rem;
        }
        h2 {
            font-size: 2rem;
        }
    }
}

// about section
@mixin about--xs--sm() {
    section.about {
        div.row {
            .general-info-container {
                @include animation(occur,1s,0.5s,1,backwards);
                
            }
            .education-info-container {
                @include animation(occur,1s,1s,1,backwards);
            }
            div.title {
                h2 {
                    text-align: left;
                }
                span {
                    display: none;
                }
            }
        }
    }
}

@mixin about--md() {
    section.about {
        .container {
            min-height: 650px;
        }
        .general-info-container,.education-info-container  {
            height: 600px;
        }
    }
}

@mixin about--md--lg() {
    section.about {
        .container {
            min-height: 600px;
        }
        div.row {
            position: relative;
            .general-info-container {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(-50%);
                @include animation(slide-right,1s,0.5s,1,backwards);
                
            }
            .education-info-container {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(-50%);
                @include animation(slide-left,1s,0.5s,1,backwards);
            }
        }
    }
}

// skills section
@mixin skills--xs--sm {
    section.skills {
        .skills-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        div.row {
            position: relative;
            .html {
                @include animation(occur,1s,0.5s,1,backwards);
            }

            .css {
                @include animation(occur,1s,0.75s,1,backwards);
            }
            .js {
                @include animation(occur,1s,1s,1,backwards);
            }
            .tools {
                @include animation(occur,1s,1.25s,1,backwards);
            }
        }
    }
}

@mixin skills--md() {
    section.skills {
        div.row {
            position: relative;
            .html {
                @include animation(occur,1s,0.5s,1,backwards);
            }

            .css {
                @include animation(occur,1s,0.75s,1,backwards);
            }
            .js {
                @include animation(occur,1s,1.25s,1,backwards);
            }
            .tools {
                @include animation(occur,1s,1s,1,backwards);
            }
        }
    }
}

@mixin skills--lg() {
    section.skills {
        div.row {
            position: relative;
            .html, .css, .js, .tools {
                position: absolute;
                top: 0;
                transform: translateY(-50%);
                height: 360px;
            }
            .html {
                left: 0;
                @include animation(show-up,1s,0.5s,1,backwards);
            }

            .css {
                left: 25%;
                @include animation(show-down,1s,0.5s,1,backwards);
            }
            .js {
                left: 50%;
                @include animation(show-up,1s,0.5s,1,backwards);
            }
            .tools {
                right: 0;
                @include animation(show-down,1s,0.5s,1,backwards);
            }
        }
    }
}

// projects section
@mixin projects--xs() {
    section.projects {
        div.project-container div.project-img-container,img {
            min-height: 137px;
        }
        .button {
            padding: 5px;
            height: 30px;
            line-height: 20px;
            vertical-align: center;
            font-size: 0.8rem;
        }
    }
}

@mixin projects--sm() {
    section.projects {
        div.project-container div.project-img-container,img {
            min-height: 274px;
        }
        .button {
            padding: 5px;
            height: 30px;
            line-height: 20px;
            vertical-align: center;
            font-size: 0.8rem;
        }
    }
}

@mixin projects--md() {
    section.projects {
        div.project-container div.project-img-container,img {
            min-height: 377px;
        }
    }
}

@mixin projects--lg() {
    section.projects {
        .project-container div.project-img-container,img {
            min-height: 300px;
        }
    }
}

// project demo section
@mixin project--demo--xs--sm() {
    section.project-demo {
        .section-title-container {
            h1 {
                font-size: 2rem;
            }
            p {
                font-size: 0.9rem;
            }
        }
        .sub-heading {
            display: flex;
            justify-content: flex-start;
        }
        h2 {
            width:100%;
            text-align: start;
            span {
                display: none;
            }
        }

        .c2r-contianer {
            .color-container {
                div.color {
                    height: 80px;
                    width: 80px;
                    text-align: center;
                    line-height: 80px;
                    margin: 10px;
                    font-size: 1rem;
                }
            }
        }
    }
}

// header 
@mixin header--xs--sm() {
    header {
        li.contact-btn-sm {
            position: relative;
            margin-right: auto;
            button.btn-warning {
                width: 50px;
                position: absolute;
                top: 0px;
                left: 0px;
                transform: translate(0,-20%);
            }
            button.btn-warning:hover {
                top: 2px;
            }
        }

        a.active::after {
            display: none;
        }
    }
}

// extra small devices || mobile devices
@media (max-width:575px) {
    @include xs();
    @include welcome--xs();
    @include about--xs--sm();
    @include skills--xs--sm();
    @include project--demo--xs--sm();
    @include projects--xs();
    @include header--xs--sm();

}

//  small devices || tablet
@media (min-width:576px) and (max-width:767px) {
    @include welcome--sm();
    @include about--xs--sm();
    @include skills--xs--sm();
    @include project--demo--xs--sm();
    @include header--xs--sm();
    @include projects--sm()

}

// medium devices 
@media (min-width:768px) and (max-width: 991px) {
    @include about--md--lg();
    @include about--md();
    @include skills--md();
    @include projects--md();
}

// large devices
@media (min-width: 992px) {
    @include welcome--lg();
    @include about--md--lg();
    @include skills--lg();
    @include projects--lg();
}
