@-webkit-keyframes loading{
    0% {left:-40px; top:0;}
    50% {left:100%; top:0;}
    100% {left:-40px; top:0;}
}

@keyframes loading {
    0% {left:-40px; top:0;}
    50% {left:100%; top:0;}
    100% {left:-40px; top:0;}
}

@-webkit-keyframes blink {
    0% {opacity: 0.5;}
    50% {opacity: 1;}
    100% {opacity: 0.5;}
}

@keyframes blink {
    0% {opacity: 0.5;}
    50% {opacity: 1;}
    100% {opacity: 0.5;}
}

@-webkit-keyframes slide-up {
    0% {left: 0; top:0; position: absolute;}
    50% {left: 0; top: -80vh; position: absolute;}
    100% {left: 0; top: -160vh; visibility: hidden;}
}

@keyframes slide-up {
    0% {left: 0; top:0; position: absolute;}
    50% {left: 0; top: -80vh; position: absolute;}
    100% {left: 0; top: -160vh; visibility: hidden;}
}

@-webkit-keyframes slide-down {
    0% {left: 0; top: -160vh; visibility: hidden;}
    50% {left: 0; top: -80vh; position: absolute; visibility: visible}
    100% {left: 0; top:0; position: absolute;}
}

@keyframes slide-down {
    0% {left: 0; top: -160vh; visibility: hidden;}
    50% {left: 0; top: -80vh; position: absolute; visibility: visible}
    100% {left: 0; top:0; position: absolute;}
}

@keyframes occur {
    from {opacity:0;}
    to {opacity:1;}
}

@-webkit-keyframes occur {
    from {opacity:0;}
    to {opacity:1;}
}

@-webkit-keyframes skill-hover {
    0% {transform: scale(1);}
    50% {transform: scale(1.2);}
    100% {transform: scale(1);}
}

@keyframes skill-hover {
    0% {transform: scale(1);}
    50% {transform: scale(1.2);}
    100% {transform: scale(1);}
}

@-webkit-keyframes rise {
    from {left:0; top:1000;}
    to {left:0; top:0;}
}

@keyframes rise {
    from {left:0; top:1000;}
    to {left:0; top:0;}
}

@-webkit-keyframes reveal {
    from {width: 100%}
    to {width: 0%}
}

@keyframes reveal {
    from {width: 100%}
    to {width: 0%}
}

@-webkit-keyframes img-up {
    from {z-index:1;}
    to {z-index: -1}
}

@keyframes img-up {
    from {z-index:1;}
    to {z-index: -1}
}

@-webkit-keyframes slide-right {
    from {opacity: 0; transform: translate(-100%, -50%)}
    to {opacity: 1; transform: translate(0, -50%)}
}

@keyframes silde-right {
    from {opacity: 0; transform: translate(-100%, -50%)}
    to {opacity: 1; transform: translate(0, -50%)}
}

@-webkit-keyframes slide-left {
    from {opacity: 0; transform: translate(100%, -50%)}
    to {opacity: 1; transform: translate(0, -50%)}
}

@keyframes silde-left {
    from {opacity: 0; transform: translate(100%, -50%)}
    to {opacity: 1; transform: translate(0, -50%)}
}

@-webkit-keyframes show-up {
    from {opacity: 0; transform: translate(0, 0)}
    to {opacity: 1; transform: translate(0, -50%)}
}

@keyframes show-up {
    from {opacity: 0; transform: translate(0, 0)}
    to {opacity: 1; transform: translate(0, -50%)}
}

@-webkit-keyframes show-down {
    from {opacity: 0; transform: translate(0, -100%)}
    to {opacity: 1; transform: translate(0, -50%)}
}

@keyframes show-down {
    from {opacity: 0; transform: translate(0, -100%)}
    to {opacity: 1; transform: translate(0, -50%)}
}

@-webkit-keyframes show-scale {
    from {opacity: 0; transform: scale(0.1)}
    to {opacity: 1; transform: scale(1)}
}

@keyframes show-scale {
    from {opacity: 0; transform: scale(0.1)}
    to {opacity: 1; transform: scale(1)}
}

@-webkit-keyframes show-scale-y {
    from {opacity: 0; transform: scaleY(0.1)}
    to {opacity: 1; transform: scaleY(1)}
}

@keyframes show-scale-y {
    from {opacity: 0; transform: scaleY(0.1)}
    to {opacity: 1; transform: scaleY(1)}
}

@-webkit-keyframes drop-down {
    from {transform: translate(0, -100%)}
    to {transform: translate(0, 0)}
}

@keyframes drop-down {
    from {transform: translate(0, -100%)}
    to {transform: translate(0, 0)}
}

@-webkit-keyframes horizontal-move-left{
    from {opacity: 0; transform: translateX(-100%)}
    to {opacity: 1; transform: translateX(0)}
}

@keyframes horizontal-move-left {
    from {opacity: 0; transform: translateX(-100%)}
    to {opacity: 1; transform: translateX(0)}
}