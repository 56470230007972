$dark: #343a40;
$lt-grey: #6b7580;
$pl-dark: #424951;
$lt-dark: #98a8b8;
$cream-white: #F9F7F0;
$pte-blue: #355B94;
$bootstrap-p: #563b7e;
$bootstrap-y: #fce27d;
$gulp-r: #d04744;
$js-y: #f7e200;
$react-bk: #282c34;
$react-bl: #5ed9fd;
$redux-p: #783fc4;
$node-gr: #66a25e;
$node-bk: #303030;
$pte-red: #C24332;


@mixin animation($name,$duration,$delay,$interation,$fill) {
    -webkit-animation-name: $name;
    -webkit-animation-duration: $duration;
    -webkit-animation-delay: $delay;
    -webkit-animation-iteration-count: $interation;
    -webkit-animation-fill-mode: $fill;
    animation-name: $name;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-iteration-count: $interation;
    animation-fill-mode: $fill;
}

@mixin absolute-position($left,$right) {
    position: absolute;
    left: $left;
    top: $right;
}

@import 'badges';
@import 'animation';
@import 'media-queries';

@import 'welcome-section';
@import 'projects-and-demo-section';
@import 'contact-section';
@import 'about-section';
@import 'skills-section';

html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Rokkitt', serif;
}

// put it in html will cause the scroll event listener stop working 
// or use document.body.addEventListener()...
body {
    overflow-x: hidden;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@for $index from 1 through 6 {
    h#{$index}{
        font-family: 'Teko', sans-serif;
        font-weight: bold;
    }
}

p {
    font-family: 'Slabo 27px', serif;
}

.transition-wrapper {
    position: relative;
}

section {
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    padding-top: 50px;
    margin-bottom: 20px;
    min-height: 600px;

    h1 {
        margin-top: 10px;
        text-transform: uppercase;
        width: 100%;
        align-self: flex-start;
        flex: 0 0 auto;
        text-align: center;
        @include animation(show-scale-y,0.5s,0,1,backwards);
    }

}


header {
    a.active {
        opacity: 1;
        font-weight: bold;
        position: relative;
    }

    a.active::after {
        content: ' ';
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $dark;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%,50%);
        z-index: 10;
    }
    li.contact-btn {
        position: relative;
        width: 74px;
    }
    button.btn-warning {
        width: 74px;
        position: absolute;
        top: 0px;
        border-radius: 4px;
        box-shadow: 0 4px #e37e00;
        border: 0px;
    }

    .btn, .btn:hover {
        -webkit-transition: none;
        transition: none;
    }

    button.btn-warning:hover {
        background-color: #ffc300;
        top: 2px;
        box-shadow: 0 2px #e37e00;
        border: 0px;
    }

    li.contact-btn-sm {
        position: relative;
        top: -13px;
    }
}


footer {
    background-color: $dark;
    height: 60px;
    padding-top: 10px;
    div {
        text-align: center;
    }

    a {
        margin: 0 5px; 
        color: white;
    }

    a:hover {
        color: #ffc400;
    }
}

// button style starts here
.button {
    height: 50px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    border: 2px solid $dark;
    margin: 10px 10px 0 0;
    padding: 10px;
    color: $dark;
    position: relative;
    box-shadow: 2px 2px 0px grey;
    span:nth-child(1) {
        @include absolute-position(0,0);
        height: 100%;
        background-color: $dark;
        z-index: -10;
        width: 0%;
        transition: width 0.5s;
        -webkit-transition: width 0.5s;
        background-color: $dark;
    }
    span:nth-child(2) {
        height: 100%;
        width: 100%;
    }
}

.button:hover {
    text-decoration: none;
    color: white;

    span:nth-child(1) {
        width: 100%
    }
}
// button style ends here

.loading-wrapper {
    width: 100%;
    min-width: 320px;
    min-height: 600px;
    height: 100vh;
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    @include animation(slide-down,1s,0s,1,forwards);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    z-index: 10;
    .loading-note {
        position: absolute;
        right: 50%;
        bottom: 50%;
        transform: translate(50%,50%);
    }
    .loading-text {
        color: white;
        -webkit-animation-name: blink;
        -webkit-animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-fill-mode: backwards;
        animation-name: blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-fill-mode: backwards;
    }
    .loading-icon {
        background-color: gray;
        width: 40px;
        height: 5px;
        position: relative;
        @include animation(loading,2s,0s,infinite,backwards);
    }
}

.loaded .loading-wrapper{
    position: absolute;
    left: 0;
    top: 0;
    @include animation(slide-up,1s,2s,1,forwards);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.nav-container{
    position: fixed;
    box-shadow: 1px 1px 5px grey;
    background-color: white;
    width: 40px;
    bottom: 135px;
    right: 0;
    border-radius: 5px;
    transform: translate(-50%,-50%);
    z-index: 5;
    ul {
        align-content: center;
        align-items: center;
    }
    
    i {
        color: gray;
        vertical-align: middle;
    }
    opacity: 0.5;
}

.nav-container:hover {
    cursor: pointer;
    i {
        color: $dark;
    }
    opacity: 1;
}

.hidden {
    display:none;
}

.modal-dialog {
    max-width: 90%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.align-center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

