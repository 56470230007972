// shared style
section.projects, section.project-demo {
    // place holder for broken img link
    img:before { 
        content: " ";
        display: block;
        position: absolute;
        top: -10px;
        left: 0;
        height: calc(100% + 10px);
        width: 100%;
        background-color: snow;
        outline: $dark 2px solid;
    }
        
        img:after { 
        content: "Broken Image of " attr(alt);
        display: block;
        font-size: 16px;
        font-style: normal;
        font-family: FontAwesome;
        color: rgb(100, 100, 100);
        
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translate(-50%,-50%);
    }
    // end of place holder
}

// project section
section.projects {
    flex-direction: column;
    .container {
        height: 100%;
        flex: 1 0 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    } 

    div.project-container:nth-child(odd) {
        @include animation(show-scale,1s,0.5s,1,backwards);
    }
    div.project-container:nth-child(even) {
        @include animation(show-scale,1s,1s,1,backwards);
    }

    .project-container{
        h2 {
            transition: font-weight 0.5s;
            -webkit-transition: font-weight 0.5s;
        }
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 20px;
        .project-img-container {
            display: flex;
            flex-direction: column;
            position: relative;
            box-shadow:0 5px 2px 0 $lt-dark;
            outline: 2px solid black;
            img {
                width: 100%;
            }

            .website-img {
                position: absolute;
                z-index: 1;
                opacity: 0;
                transition: opacity 0.5s;
            }

            .img-spinner {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }

    }



    .project-container:hover {
        .project-img-container {
            box-shadow:0 10px 20px 0 $lt-dark;
            transform: translateY(-1px);
            transition: all 0.2s ease-out;
        }
    }
}


// project demo section
section.project-demo {
    margin-bottom: 0;
    padding: 0px;
    flex-direction: column;
    position: relative;
    article {
        @include animation(occur,0.5s,1.5s,1,backwards);
        padding: 10px;
        h2 {
            position: relative;
            span { 
                opacity: 0.3;
                text-transform: uppercase;
                font-size: 2.5rem;
                font-weight: bold;
                @include absolute-position(5%,45%);
            }
        }
        .breaker {
            margin-top: 40px; 
        }

        .sub-heading {
            margin: 20px 0;
        }

        ul li {
            font-weight: bold;
            margin: 10px 0;
        }

        .c2r-contianer {
            .color-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                div {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 100px;
                    margin: 10px;
                    font-weight: bold;
                    font-family: 'Amatic SC', cursive;
                    font-size: 1.2rem;
                }
            }
            .color-des {
                margin-top: 10px;
                font-style: italic;
                text-align: center;
            }
        }
    }

    .section-title-container{
        width: 100%;
        background-color: $pte-red;
        position: relative;
        margin-bottom: 36px;
        span.mask {
            position: absolute;
            background-color: white;
            height: 200px;
            z-index: 1;
            top: 0;
            @include animation(reveal,0.5s,0s,1,backwards);
        }
        h1 {
            @include animation(show-scale-y,0.5s,0.5s,1,backwards);
        }
        * {
            text-align: center;
            color: white;
            font-weight: bold;
        }

        div:nth-child(1) {
            margin-top: 5px;
            font-size: 1.25rem;
            font-family: 'Shadows Into Light', cursive;
            @include animation(show-scale-y,0.5s,0.5s,1,backwards);
        }

        div.publish-date {
            padding: 5px;
            background-color: wheat;
            z-index: -1;
            color: $dark;
            font-size: 1.1rem;
            position: absolute;
            width: 100%;
            @include animation(drop-down,0.5s,1s,1,backwards);

        }

        p {
            font-style: italic;
            padding: 0 5px;
            @include animation(occur,0.5s,0.5s,1,backwards);
        }
    }

    .intro-container {
        div.word-cloud-container{
            display: flex;
            justify-content: center;
            height: 300px;
        }
        img {
            align-self: center;
        }
    }
    .container {
        height: 100%;
        flex: 1 0 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .keyword-cloud{
            max-width: 450px;
            width: 100%;
        }
    } 

    .subsection {
        h3 {
            font-size: 0.9rem;
            order: 0;
        }
    }

    .section-footer {
        background-color: $pl-dark;
        height: 70px;
        width: 100%;
        * {
            text-align: center;
            color: white;
            font-weight: bold;
        }
        .row {
            justify-content: space-between;
        }
        .footer-link {
            display: inline-block;
            height: 30px;
            vertical-align: middle;
            line-height: 30px;
            padding: 0 10px;
            text-decoration: underline;
        }

    }
    .carousel-item {
        img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }
    .carousel {
        .carousel-control-next-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='343a40' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
        }
        .carousel-control-prev-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='343a40' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
        }
        .carousel-indicators {
            li {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $dark;
            }
        }
    }

}