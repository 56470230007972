body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.badge {
  margin-right: 2px; }

.badge-JavaScript {
  background-color: #f7e200;
  color: black; }

.badge-Bootstrap {
  background-color: #563b7e;
  color: #fce27d; }

.badge-Gulp {
  background-color: #d04744;
  color: white; }

.badge-React {
  background-color: #282c34;
  color: #5ed9fd; }

.badge-Redux {
  background-color: #783fc4;
  color: white; }

.badge-Node {
  background-color: #303030;
  color: #66a25e; }

@-webkit-keyframes loading {
  0% {
    left: -40px;
    top: 0; }
  50% {
    left: 100%;
    top: 0; }
  100% {
    left: -40px;
    top: 0; } }

@keyframes loading {
  0% {
    left: -40px;
    top: 0; }
  50% {
    left: 100%;
    top: 0; }
  100% {
    left: -40px;
    top: 0; } }

@-webkit-keyframes blink {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

@keyframes blink {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

@-webkit-keyframes slide-up {
  0% {
    left: 0;
    top: 0;
    position: absolute; }
  50% {
    left: 0;
    top: -80vh;
    position: absolute; }
  100% {
    left: 0;
    top: -160vh;
    visibility: hidden; } }

@keyframes slide-up {
  0% {
    left: 0;
    top: 0;
    position: absolute; }
  50% {
    left: 0;
    top: -80vh;
    position: absolute; }
  100% {
    left: 0;
    top: -160vh;
    visibility: hidden; } }

@-webkit-keyframes slide-down {
  0% {
    left: 0;
    top: -160vh;
    visibility: hidden; }
  50% {
    left: 0;
    top: -80vh;
    position: absolute;
    visibility: visible; }
  100% {
    left: 0;
    top: 0;
    position: absolute; } }

@keyframes slide-down {
  0% {
    left: 0;
    top: -160vh;
    visibility: hidden; }
  50% {
    left: 0;
    top: -80vh;
    position: absolute;
    visibility: visible; }
  100% {
    left: 0;
    top: 0;
    position: absolute; } }

@keyframes occur {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes occur {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes skill-hover {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes skill-hover {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes rise {
  from {
    left: 0;
    top: 1000; }
  to {
    left: 0;
    top: 0; } }

@keyframes rise {
  from {
    left: 0;
    top: 1000; }
  to {
    left: 0;
    top: 0; } }

@-webkit-keyframes reveal {
  from {
    width: 100%; }
  to {
    width: 0%; } }

@keyframes reveal {
  from {
    width: 100%; }
  to {
    width: 0%; } }

@-webkit-keyframes img-up {
  from {
    z-index: 1; }
  to {
    z-index: -1; } }

@keyframes img-up {
  from {
    z-index: 1; }
  to {
    z-index: -1; } }

@-webkit-keyframes slide-right {
  from {
    opacity: 0;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@-webkit-keyframes silde-right {
  from {
    opacity: 0;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@keyframes silde-right {
  from {
    opacity: 0;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@-webkit-keyframes slide-left {
  from {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@-webkit-keyframes silde-left {
  from {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@keyframes silde-left {
  from {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@-webkit-keyframes show-up {
  from {
    opacity: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@keyframes show-up {
  from {
    opacity: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@-webkit-keyframes show-down {
  from {
    opacity: 0;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@keyframes show-down {
  from {
    opacity: 0;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); } }

@-webkit-keyframes show-scale {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1);
            transform: scale(0.1); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes show-scale {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1);
            transform: scale(0.1); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes show-scale-y {
  from {
    opacity: 0;
    -webkit-transform: scaleY(0.1);
            transform: scaleY(0.1); }
  to {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); } }

@keyframes show-scale-y {
  from {
    opacity: 0;
    -webkit-transform: scaleY(0.1);
            transform: scaleY(0.1); }
  to {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); } }

@-webkit-keyframes drop-down {
  from {
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes drop-down {
  from {
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@-webkit-keyframes horizontal-move-left {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes horizontal-move-left {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@media (max-width: 575px) {
  html body {
    min-width: 320px;
    overflow-x: auto; }
  .welcome-img {
    display: none; }
  section.about div.row .general-info-container {
    -webkit-animation-name: occur;
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 0.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: occur;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards; }
  section.about div.row .education-info-container {
    -webkit-animation-name: occur;
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: occur;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards; }
  section.about div.row div.title h2 {
    text-align: left; }
  section.about div.row div.title span {
    display: none; }
  section.skills .skills-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  section.skills div.row {
    position: relative; }
    section.skills div.row .html {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .css {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.75s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 0.75s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .js {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 1s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .tools {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 1.25s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 1.25s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
  section.project-demo .section-title-container h1 {
    font-size: 2rem; }
  section.project-demo .section-title-container p {
    font-size: 0.9rem; }
  section.project-demo .sub-heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
  section.project-demo h2 {
    width: 100%;
    text-align: start; }
    section.project-demo h2 span {
      display: none; }
  section.project-demo .c2r-contianer .color-container div.color {
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 80px;
    margin: 10px;
    font-size: 1rem; }
  section.projects div.project-container div.project-img-container, section.projects img {
    min-height: 137px; }
  section.projects .button {
    padding: 5px;
    height: 30px;
    line-height: 20px;
    vertical-align: center;
    font-size: 0.8rem; }
  header li.contact-btn-sm {
    position: relative;
    margin-right: auto; }
    header li.contact-btn-sm button.btn-warning {
      width: 50px;
      position: absolute;
      top: 0px;
      left: 0px;
      -webkit-transform: translate(0, -20%);
              transform: translate(0, -20%); }
    header li.contact-btn-sm button.btn-warning:hover {
      top: 2px; }
  header a.active::after {
    display: none; } }

@media (min-width: 576px) and (max-width: 767px) {
  .welcome-img {
    margin-top: 20px; }
  section.about div.row .general-info-container {
    -webkit-animation-name: occur;
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 0.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: occur;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards; }
  section.about div.row .education-info-container {
    -webkit-animation-name: occur;
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: occur;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards; }
  section.about div.row div.title h2 {
    text-align: left; }
  section.about div.row div.title span {
    display: none; }
  section.skills .skills-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  section.skills div.row {
    position: relative; }
    section.skills div.row .html {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .css {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.75s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 0.75s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .js {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 1s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .tools {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 1.25s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 1.25s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
  section.project-demo .section-title-container h1 {
    font-size: 2rem; }
  section.project-demo .section-title-container p {
    font-size: 0.9rem; }
  section.project-demo .sub-heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
  section.project-demo h2 {
    width: 100%;
    text-align: start; }
    section.project-demo h2 span {
      display: none; }
  section.project-demo .c2r-contianer .color-container div.color {
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 80px;
    margin: 10px;
    font-size: 1rem; }
  header li.contact-btn-sm {
    position: relative;
    margin-right: auto; }
    header li.contact-btn-sm button.btn-warning {
      width: 50px;
      position: absolute;
      top: 0px;
      left: 0px;
      -webkit-transform: translate(0, -20%);
              transform: translate(0, -20%); }
    header li.contact-btn-sm button.btn-warning:hover {
      top: 2px; }
  header a.active::after {
    display: none; }
  section.projects div.project-container div.project-img-container, section.projects img {
    min-height: 274px; }
  section.projects .button {
    padding: 5px;
    height: 30px;
    line-height: 20px;
    vertical-align: center;
    font-size: 0.8rem; } }

@media (min-width: 768px) and (max-width: 991px) {
  section.about .container {
    min-height: 600px; }
  section.about div.row {
    position: relative; }
    section.about div.row .general-info-container {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-animation-name: slide-right;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: slide-right;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.about div.row .education-info-container {
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-animation-name: slide-left;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: slide-left;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
  section.about .container {
    min-height: 650px; }
  section.about .general-info-container, section.about .education-info-container {
    height: 600px; }
  section.skills div.row {
    position: relative; }
    section.skills div.row .html {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .css {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.75s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 0.75s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .js {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 1.25s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 1.25s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .tools {
      -webkit-animation-name: occur;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 1s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
  section.projects div.project-container div.project-img-container, section.projects img {
    min-height: 377px; } }

@media (min-width: 992px) {
  section.welcome h1 {
    font-size: 3rem; }
  section.welcome h2 {
    font-size: 2rem; }
  section.about .container {
    min-height: 600px; }
  section.about div.row {
    position: relative; }
    section.about div.row .general-info-container {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-animation-name: slide-right;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: slide-right;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.about div.row .education-info-container {
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-animation-name: slide-left;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: slide-left;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
  section.skills div.row {
    position: relative; }
    section.skills div.row .html, section.skills div.row .css, section.skills div.row .js, section.skills div.row .tools {
      position: absolute;
      top: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      height: 360px; }
    section.skills div.row .html {
      left: 0;
      -webkit-animation-name: show-up;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: show-up;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .css {
      left: 25%;
      -webkit-animation-name: show-down;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: show-down;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .js {
      left: 50%;
      -webkit-animation-name: show-up;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: show-up;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.skills div.row .tools {
      right: 0;
      -webkit-animation-name: show-down;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: show-down;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
  section.projects .project-container div.project-img-container, section.projects img {
    min-height: 300px; } }

section.welcome .container, section.welcome .row {
  height: 100%; }

section.welcome h1 {
  -webkit-animation-name: occur;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-delay: 0;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
  animation-name: occur;
  animation-duration: 0.5s;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  text-transform: none;
  text-align: left; }
  section.welcome h1 rt {
    text-align: center; }

section.welcome h2 {
  -webkit-animation-name: show-scale-y;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
  animation-name: show-scale-y;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 100; }

section.welcome ruby rt {
  font-weight: bold; }

section.welcome .welcome-img {
  -webkit-animation-name: occur;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
  animation-name: occur;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  -webkit-align-self: center;
          align-self: center; }
  section.welcome .welcome-img .base-img {
    width: 100%;
    position: relative; }
  section.welcome .welcome-img .base-img-eye-right {
    width: 5%;
    position: absolute;
    top: 18%;
    right: 50%;
    background-color: transparent; }
  section.welcome .welcome-img .base-img-eye-left {
    width: 5%;
    position: absolute;
    top: 18%;
    left: 52%;
    background-color: transparent; }

section.welcome .welcome-text {
  -webkit-align-self: center;
          align-self: center;
  position: relative;
  margin-bottom: 60px; }
  section.welcome .welcome-text .button-group {
    position: absolute;
    -webkit-animation-name: horizontal-move-left;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: horizontal-move-left;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards; }

section.projects img:before, section.project-demo img:before {
  content: " ";
  display: block;
  position: absolute;
  top: -10px;
  left: 0;
  height: calc(100% + 10px);
  width: 100%;
  background-color: snow;
  outline: #343a40 2px solid; }

section.projects img:after, section.project-demo img:after {
  content: "Broken Image of " attr(alt);
  display: block;
  font-size: 16px;
  font-style: normal;
  font-family: FontAwesome;
  color: #646464;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

section.projects {
  -webkit-flex-direction: column;
          flex-direction: column; }
  section.projects .container {
    height: 100%;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  section.projects div.project-container:nth-child(odd) {
    -webkit-animation-name: show-scale;
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 0.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: show-scale;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards; }
  section.projects div.project-container:nth-child(even) {
    -webkit-animation-name: show-scale;
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: show-scale;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards; }
  section.projects .project-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 20px; }
    section.projects .project-container h2 {
      transition: font-weight 0.5s;
      -webkit-transition: font-weight 0.5s; }
    section.projects .project-container .project-img-container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      position: relative;
      box-shadow: 0 5px 2px 0 #98a8b8;
      outline: 2px solid black; }
      section.projects .project-container .project-img-container img {
        width: 100%; }
      section.projects .project-container .project-img-container .website-img {
        position: absolute;
        z-index: 1;
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s; }
      section.projects .project-container .project-img-container .img-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
  section.projects .project-container:hover .project-img-container {
    box-shadow: 0 10px 20px 0 #98a8b8;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }

section.project-demo {
  margin-bottom: 0;
  padding: 0px;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative; }
  section.project-demo article {
    -webkit-animation-name: occur;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-delay: 1.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: occur;
    animation-duration: 0.5s;
    animation-delay: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    padding: 10px; }
    section.project-demo article h2 {
      position: relative; }
      section.project-demo article h2 span {
        opacity: 0.3;
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: bold;
        position: absolute;
        left: 5%;
        top: 45%; }
    section.project-demo article .breaker {
      margin-top: 40px; }
    section.project-demo article .sub-heading {
      margin: 20px 0; }
    section.project-demo article ul li {
      font-weight: bold;
      margin: 10px 0; }
    section.project-demo article .c2r-contianer .color-container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: center;
              justify-content: center; }
      section.project-demo article .c2r-contianer .color-container div {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        line-height: 100px;
        margin: 10px;
        font-weight: bold;
        font-family: 'Amatic SC', cursive;
        font-size: 1.2rem; }
    section.project-demo article .c2r-contianer .color-des {
      margin-top: 10px;
      font-style: italic;
      text-align: center; }
  section.project-demo .section-title-container {
    width: 100%;
    background-color: #C24332;
    position: relative;
    margin-bottom: 36px; }
    section.project-demo .section-title-container span.mask {
      position: absolute;
      background-color: white;
      height: 200px;
      z-index: 1;
      top: 0;
      -webkit-animation-name: reveal;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-delay: 0s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: reveal;
      animation-duration: 0.5s;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.project-demo .section-title-container h1 {
      -webkit-animation-name: show-scale-y;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: show-scale-y;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.project-demo .section-title-container * {
      text-align: center;
      color: white;
      font-weight: bold; }
    section.project-demo .section-title-container div:nth-child(1) {
      margin-top: 5px;
      font-size: 1.25rem;
      font-family: 'Shadows Into Light', cursive;
      -webkit-animation-name: show-scale-y;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: show-scale-y;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.project-demo .section-title-container div.publish-date {
      padding: 5px;
      background-color: wheat;
      z-index: -1;
      color: #343a40;
      font-size: 1.1rem;
      position: absolute;
      width: 100%;
      -webkit-animation-name: drop-down;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-delay: 1s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: drop-down;
      animation-duration: 0.5s;
      animation-delay: 1s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
    section.project-demo .section-title-container p {
      font-style: italic;
      padding: 0 5px;
      -webkit-animation-name: occur;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-delay: 0.5s;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-fill-mode: backwards;
      animation-name: occur;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards; }
  section.project-demo .intro-container div.word-cloud-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 300px; }
  section.project-demo .intro-container img {
    -webkit-align-self: center;
            align-self: center; }
  section.project-demo .container {
    height: 100%;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
    section.project-demo .container .keyword-cloud {
      max-width: 450px;
      width: 100%; }
  section.project-demo .subsection h3 {
    font-size: 0.9rem;
    -webkit-order: 0;
            order: 0; }
  section.project-demo .section-footer {
    background-color: #424951;
    height: 70px;
    width: 100%; }
    section.project-demo .section-footer * {
      text-align: center;
      color: white;
      font-weight: bold; }
    section.project-demo .section-footer .row {
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    section.project-demo .section-footer .footer-link {
      display: inline-block;
      height: 30px;
      vertical-align: middle;
      line-height: 30px;
      padding: 0 10px;
      text-decoration: underline; }
  section.project-demo .carousel-item img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }
  section.project-demo .carousel .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='343a40' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }
  section.project-demo .carousel .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='343a40' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }
  section.project-demo .carousel .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #343a40; }

section.contact .container, section.contact .row {
  height: 100%; }

section.contact .mailbox-img {
  -webkit-align-self: center;
          align-self: center; }
  section.contact .mailbox-img .base-img {
    width: 100%;
    position: relative; }
  section.contact .mailbox-img .base-img-eye-right {
    width: 5%;
    position: absolute;
    top: 18%;
    right: 50%;
    background-color: transparent; }
  section.contact .mailbox-img .base-img-eye-left {
    width: 5%;
    position: absolute;
    top: 18%;
    left: 52%;
    background-color: transparent; }

section.contact .form-text {
  -webkit-align-self: center;
          align-self: center; }

section.contact .form-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  section.contact .form-buttons button {
    background-color: white; }

section.contact .submit-btn {
  color: #C24332;
  border-color: #C24332; }

section.contact .cancel-btn:hover {
  background-color: #343a40; }

section.contact .submit-btn:hover {
  color: white;
  background-color: #C24332; }

section.about {
  -webkit-flex-direction: column;
          flex-direction: column; }
  section.about .container {
    height: 100%;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  section.about .general-info-container, section.about .education-info-container {
    padding: 20px;
    min-height: 500px;
    box-shadow: 0 5px 2px 0 #98a8b8;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    outline-offset: -5px; }
    section.about .general-info-container .space-filler, section.about .education-info-container .space-filler {
      -webkit-flex: 1 0 auto;
              flex: 1 0 auto; }
  section.about .general-info-container {
    background-color: snow;
    padding-bottom: 5px;
    outline: 1px solid #343a40; }
    section.about .general-info-container .title {
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-align-self: baseline;
              align-self: baseline;
      position: relative;
      width: 100%; }
      section.about .general-info-container .title span {
        width: 100%;
        color: #424951;
        opacity: 0.3;
        text-transform: uppercase;
        font-size: 3.5rem;
        font-weight: bold;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transform: translate(0, -30%);
                transform: translate(0, -30%); }
  section.about .education-info-container {
    background-color: #424951;
    color: white;
    outline: 1px solid snow; }
    section.about .education-info-container .title {
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-align-self: flex-start;
              align-self: flex-start;
      position: relative;
      width: 100%;
      text-align: end; }
      section.about .education-info-container .title span {
        width: 100%;
        color: white;
        opacity: 0.1;
        text-transform: uppercase;
        font-size: 3.5rem;
        font-weight: bold;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transform: translate(0, -20%);
                transform: translate(0, -20%); }
  section.about ul li {
    margin-top: 20px; }
    section.about ul li p {
      font-weight: bold;
      margin-bottom: 3px; }
      section.about ul li p span {
        font-size: 0.9rem;
        font-weight: 100; }
  section.about ul li > span {
    font-style: italic;
    font-size: 0.9rem; }

section.skills {
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  background-color: white; }
  section.skills .container {
    height: 100%;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  section.skills .css, section.skills .tools {
    padding: 10px 10px 20px 10px;
    background-color: snow;
    outline: 1px solid #424951;
    outline-offset: -5px; }
  section.skills .html, section.skills .js {
    padding: 10px 10px 20px 10px;
    background-color: #424951;
    color: snow;
    outline: 1px solid snow;
    outline-offset: -5px; }
  section.skills .html:hover, section.skills .css:hover, section.skills .js:hover, section.skills .tools:hover {
    cursor: pointer; }
    section.skills .html:hover .skills-icon span, section.skills .css:hover .skills-icon span, section.skills .js:hover .skills-icon span, section.skills .tools:hover .skills-icon span {
      -webkit-animation-name: skill-hover;
      -webkit-animation-duration: 1s;
      -webkit-animation-delay: 0s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-fill-mode: forwards;
      animation-name: skill-hover;
      animation-duration: 1s;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards; }
  section.skills .skills-icon {
    min-height: 50px;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 10px; }
    section.skills .skills-icon span {
      background-color: transparent; }
  section.skills .skills-text {
    min-height: 200px; }
    section.skills .skills-text span.keyword {
      font-weight: bold; }

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Rokkitt', serif; }

body {
  overflow-x: hidden; }

.App {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

h1 {
  font-family: 'Teko', sans-serif;
  font-weight: bold; }

h2 {
  font-family: 'Teko', sans-serif;
  font-weight: bold; }

h3 {
  font-family: 'Teko', sans-serif;
  font-weight: bold; }

h4 {
  font-family: 'Teko', sans-serif;
  font-weight: bold; }

h5 {
  font-family: 'Teko', sans-serif;
  font-weight: bold; }

h6 {
  font-family: 'Teko', sans-serif;
  font-weight: bold; }

p {
  font-family: 'Slabo 27px', serif; }

.transition-wrapper {
  position: relative; }

section {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  padding-top: 50px;
  margin-bottom: 20px;
  min-height: 600px; }
  section h1 {
    margin-top: 10px;
    text-transform: uppercase;
    width: 100%;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    text-align: center;
    -webkit-animation-name: show-scale-y;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-delay: 0;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: backwards;
    animation-name: show-scale-y;
    animation-duration: 0.5s;
    animation-delay: 0;
    animation-iteration-count: 1;
    animation-fill-mode: backwards; }

header a.active {
  opacity: 1;
  font-weight: bold;
  position: relative; }

header a.active::after {
  content: ' ';
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #343a40;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  z-index: 10; }

header li.contact-btn {
  position: relative;
  width: 74px; }

header button.btn-warning {
  width: 74px;
  position: absolute;
  top: 0px;
  border-radius: 4px;
  box-shadow: 0 4px #e37e00;
  border: 0px; }

header .btn, header .btn:hover {
  -webkit-transition: none;
  transition: none; }

header button.btn-warning:hover {
  background-color: #ffc300;
  top: 2px;
  box-shadow: 0 2px #e37e00;
  border: 0px; }

header li.contact-btn-sm {
  position: relative;
  top: -13px; }

footer {
  background-color: #343a40;
  height: 60px;
  padding-top: 10px; }
  footer div {
    text-align: center; }
  footer a {
    margin: 0 5px;
    color: white; }
  footer a:hover {
    color: #ffc400; }

.button {
  height: 50px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  border: 2px solid #343a40;
  margin: 10px 10px 0 0;
  padding: 10px;
  color: #343a40;
  position: relative;
  box-shadow: 2px 2px 0px grey; }
  .button span:nth-child(1) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #343a40;
    z-index: -10;
    width: 0%;
    transition: width 0.5s;
    -webkit-transition: width 0.5s;
    background-color: #343a40; }
  .button span:nth-child(2) {
    height: 100%;
    width: 100%; }

.button:hover {
  text-decoration: none;
  color: white; }
  .button:hover span:nth-child(1) {
    width: 100%; }

.loading-wrapper {
  width: 100%;
  min-width: 320px;
  min-height: 600px;
  height: 100vh;
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation-name: slide-down;
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-name: slide-down;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  z-index: 10; }
  .loading-wrapper .loading-note {
    position: absolute;
    right: 50%;
    bottom: 50%;
    -webkit-transform: translate(50%, 50%);
            transform: translate(50%, 50%); }
  .loading-wrapper .loading-text {
    color: white;
    -webkit-animation-name: blink;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-fill-mode: backwards;
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: backwards; }
  .loading-wrapper .loading-icon {
    background-color: gray;
    width: 40px;
    height: 5px;
    position: relative;
    -webkit-animation-name: loading;
    -webkit-animation-duration: 2s;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-fill-mode: backwards;
    animation-name: loading;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-fill-mode: backwards; }

.loaded .loading-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation-name: slide-up;
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-name: slide-up;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.nav-container {
  position: fixed;
  box-shadow: 1px 1px 5px grey;
  background-color: white;
  width: 40px;
  bottom: 135px;
  right: 0;
  border-radius: 5px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 5;
  opacity: 0.5; }
  .nav-container ul {
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .nav-container i {
    color: gray;
    vertical-align: middle; }

.nav-container:hover {
  cursor: pointer;
  opacity: 1; }
  .nav-container:hover i {
    color: #343a40; }

.hidden {
  display: none; }

.modal-dialog {
  max-width: 90%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px; }

.align-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }

.align-center-horizontal {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center; }

