section.welcome {
    .container, .row {
        height: 100%;
    } 
    h1 {
        @include animation(occur,0.5s,0,1,backwards);
        text-transform: none;
        text-align: left;
        rt {
            text-align: center;
        }
    }

    h2 {
        @include animation(show-scale-y,0.5s,0.5s,1,backwards);
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 100;
    }

    ruby rt {
        font-weight: bold;
    }

    .welcome-img {
        @include animation(occur,1.5s,0.5s,1,backwards);
        align-self: center;
        .base-img{
            width: 100%;
            position: relative;
        }
        .base-img-eye-right{
            width: 5%;
            position: absolute;
            top:18%;
            right:50%;
            background-color: transparent;
        }

        .base-img-eye-left{
            width: 5%;
            position: absolute;
            top:18%;
            left:52%;
            background-color: transparent;
        }
    }

    .welcome-text {
        align-self: center;
        position: relative;
        margin-bottom: 60px;
        .button-group {
            position: absolute;
            @include animation(horizontal-move-left,0.5s,1s,1,backwards);
        }
    }
}
